export const getFestival = /* GraphQL */ `
    query GetFestival($id: ID!) {
        getFestival(id: $id) {
            id
            slug
            name
            site
            zone {
                id
                name
            }
            address {
                line1
                line2
                city
                county
                state
                zip
            }
            state {
                enabled
            }
            createdAt
        }
    }
`;

export const getFestivalBySlug = /* GraphQL */ `
    query GetFestivalBySlug(
        $slug: String!
        $sortDirection: ModelSortDirection
        $filter: ModelFestivalFilterInput
        $limit: Int
        $nextToken: String
    ) {
        getFestivalBySlug(
            slug: $slug
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                slug
                name
                site
                zone {
                    id
                    name
                }
                address {
                    line1
                    line2
                    city
                    county
                    state
                    zip
                }
                state {
                    enabled
                }
            }
        }
    }
`;

export const listZones = /* GraphQL */ `
    query ListZones(
        $id: ID
        $filter: ModelZoneFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listZones(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                counties
                state {
                    enabled
                }
            }
        }
    }
`;

export const onUpdateZone = /* GraphQL */ `
    subscription OnUpdateZone {
        onUpdateZone {
            id
            name
            state {
                enabled
            }
        }
    }
`;

