<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs="{ }">
      <b-breadcrumb-item :text="`Management - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Festivals" :to="{ name: 'management-festivals' }" />
      <b-breadcrumb-item :text="festival ? festival.name : 'Festival'" active />
    </template>

    <template #actions="{ state }">
      <can do="update" on="management-festival">
        <b-button v-if="state.editing" v-b-tooltip="'Update'" variant="transparent" size="sm" @click="updateFestival">
          <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" />
        </b-button>
      </can>
    </template>

    <template #dropdown-options="{ state }">
      <can do="update" on="management-festival">
        <b-dropdown-item @click="state.editing = !state.editing">
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
        <template v-if="festival">
          <b-dropdown-item @click="updateState">
            <font-awesome-icon :icon="['fas', festival.state.enabled ? 'toggle-off' : 'toggle-on']"></font-awesome-icon>
            <span class="align-middle ml-50">{{ festival.state.enabled ? 'Disable' : 'Enable' }}</span>
          </b-dropdown-item>
          <can do="delete" on="management-festival">
            <b-dropdown-item @click="$refs.layout.confirmDelete(festival, deleteFestival, cascadeConfirmDeleteOptions)">
              <feather-icon icon="Trash2Icon"/>
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </can>
        </template>

        <b-dropdown-divider/>
      </can>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{ state }">
      <template v-if="error">
        <b-alert id="application-error" show variant="danger">
          <p>
            <b-icon-exclamation-circle class="mr-25"/>
            {{ error }}
          </p>
        </b-alert>
      </template>
      <validation-observer v-else ref="observer" tag="div">
        <form ref="form">
          <b-row>
            <b-col cols="8">
              <b-card>
                <b-row>
                  <b-col align-self="center" cols="auto">
                    <b-avatar v-if="hasCustomAvatar(festival.avatar)" v-b-modal.avatar-modal variant="primary" size="8em" badge-variant="white" :disabled="!state.editing">
                      <b-img fluid fluid-grow :src="festival.avatar.src" :alt="festival.avatar.name"></b-img>
                      <template #badge>
                        <b-icon :icon="enabledIcon(festival.state.enabled)" :variant="enabledIconVariant(festival.state.enabled)"></b-icon>
                      </template>
                    </b-avatar>
                    <b-avatar v-else v-b-modal.avatar-modal variant="primary" size="6em" badge-variant="white" :disabled="!state.editing">
                      <font-awesome-icon :icon="icon" size="3x"></font-awesome-icon>
                      <template #badge>
                        <b-icon :icon="enabledIcon(festival.state.enabled)" :variant="enabledIconVariant(festival.state.enabled)"></b-icon>
                      </template>
                    </b-avatar>
                    <avatar-modal v-if="state.editing"
                                  title="Festival Avatar"
                                  :avatar="festival.avatar"
                                  @update-avatar="updateAvatar"/>
                  </b-col>
                  <b-col align-self="center">
                    <validation-provider v-slot="validationContext" vid="name" name="Festival Name" rules="required">
                      <b-form-group label="Festival Name" label-for="name-input" :invalid-feedback="validationContext.errors[0]">
                        <b-input v-model="festival.name" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-input>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col>
              <b-card>
                <template>
                  <validation-provider v-slot="validationContext" vid="zone" name="Zone" rules="required">
                    <b-form-group label="Zone" label-for="zone-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                      <v-select id="zone-input"
                                v-model="festival.zone"
                                :disabled="!state.editing"
                                label="name"
                                :loading="options.zones.loading"
                                :options="options.zones.items"
                                :selectable="option => option.state.enabled === true"
                                :select-on-tab="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                placeholder="Zone"
                                class="form-control-plaintext w-100"
                                @input="festival.address.county = null">
                        <template #option="{ name, state }">
                          <div class="d-flex justify-content-between">
                            <span>{{ name }}</span> <span v-if="!state.enabled" title="Disabled"><b-icon-lock-fill /></span>
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </validation-provider>
                </template>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card header="Festival Location">
                <b-row>
                  <b-col>
                    <validation-provider v-slot="validationContext" vid="site" name="Site" rules="required">
                      <b-form-group label="Site" label-for="site-input" :invalid-feedback="validationContext.errors[0]">
                        <b-form-input id="site-input" v-model="festival.site" :disabled="!state.editing" :state="getValidationState(validationContext)" placeholder="{x} High School, {x} College, etc..."></b-form-input>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <validation-provider v-slot="validationContext" vid="line1" name="Line 1" rules="required">
                      <b-form-group label="Line 1" label-for="line1-input" :invalid-feedback="validationContext.errors[0]">
                        <b-form-input id="line1-input" v-model="festival.address.line1" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-form-input>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <validation-provider v-slot="validationContext" vid="line2" name="Line 2" rules="">
                      <b-form-group label="Line 2" label-for="line2-input" :invalid-feedback="validationContext.errors[0]">
                        <b-form-input id="line2-input" v-model="festival.address.line2" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-form-input>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <validation-provider v-slot="validationContext" vid="city" name="City" rules="required">
                      <b-form-group label="City" label-for="city-input" :invalid-feedback="validationContext.errors[0]">
                        <b-form-input id="city-input" v-model="festival.address.city" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-form-input>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <validation-provider v-slot="validationContext" vid="county" name="County" rules="required">
                      <b-form-group label="County" label-for="county-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                        <v-select id="county-input"
                                  v-model="festival.address.county"
                                  :options="counties"
                                  :select-on-tab="true"
                                  :disabled="!state.editing"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  class="w-100"/>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="auto">
                    <validation-provider v-slot="validationContext" vid="state" name="State" rules="required">
                      <b-form-group label="State" label-for="state-input" :invalid-feedback="validationContext.errors[0]">
<!--                        <b-form-input id="state-input" v-model="festival.address.state" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-form-input>-->
                        <state-input id="state-input"
                                     v-model="festival.address.state"
                                     placeholder="NY"
                                     :disabled="!state.editing"
                                     :validation-state="getValidationState(validationContext)"/>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="auto">
                    <validation-provider v-slot="validationContext" vid="zip-code" name="Zip Code" rules="required">
                      <b-form-group label="Zip Code" label-for="zip-code-input" :invalid-feedback="validationContext.errors[0]">
<!--                        <b-form-input id="zip-code-input" v-model="festival.address.zip" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-form-input>-->
                        <zip-input id="zip-code-input"
                                   v-model="festival.address.zip"
                                   :disabled="!state.editing"
                                   :validation-state="getValidationState(validationContext)" />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="">
              <b-card title="Other" sub-title="Festivals need to be unique from year to year. You should not be changing this value in order to make it appear in a different year." class="border-danger">
                <b-form-group label="Created At" label-class="pt-1">
                  <b-input-group>
                    <b-datepicker v-model="createdAtDate" hide-header nav-button-variant="primary" :disabled="!state.editing" />
                    <b-timepicker v-model="createdAtTime" :disabled="!state.editing" close-button-variant="primary" button-variant="primary" hide-header  />
                  </b-input-group>
                </b-form-group>
              </b-card>

            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </template>

    <template #debug>
      <debug title="Festival">{{ festival }}</debug>
      <debug title="Zones">{{ options.zones }}</debug>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import AvatarModal from '@/components/Avatar/AvatarModal.vue';
import slugify from 'slugify';
import vSelect from 'vue-select'
import avatar from '@/mixins/avatar.mixin'
import notify from '@/mixins/notify.mixin'
import status from '@/mixins/status.mixin'
import role from '@/mixins/role.mixin';
import print from '@/mixins/print.mixin';
import StateInput from '@/components/StateInput.vue';
import ZipInput from '@/components/ZipInput.vue';
import { API, graphqlOperation } from 'aws-amplify';
import { getFestival, getFestivalBySlug, listZones, onUpdateZone } from '@/graphql/queries/festival';
import {deleteFestival, updateFestival} from '@/graphql/mutations';
import { cascadeDeleteFestival, cascadeConfirmDeleteOptions} from '@/graphql/cascade/festival';
import flatPickr from 'vue-flatpickr-component';
import settingsMixin from '@/mixins/settings.mixin';


export default {
  components: {
    PageLayout,
    ZipInput,
    StateInput,
    AvatarModal,
    vSelect,
    flatPickr
  },
  mixins: [ avatar, role, status, print, notify, settingsMixin ],
  props: {
    id: {
      type: String,
      required: false,
      default: null
    },
    slug: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      festival: null,
      error: null,
      icon: 'fas fa-headphones',
      createdAtDate: null,
      createdAtTime: null,
      options: {
        zones: {
          items: [],
          loading: true,
          subscription: null
        }
      },
      cascadeConfirmDeleteOptions
    }
  },
  computed: {
    counties() {
      return this.festival && this.festival.zone ? this.options.zones.items.find(zone => zone.id === this.festival.zone.id)?.counties.sort((a, b) => a.localeCompare(b)) : []
    },
    formattedCreatedAt() {
      return `${this.createdAtDate}T${this.createdAtTime}.999Z`
    }
  },
  async mounted() {
    await this.getFestival();
    await this.getZones();
    await this.onUpdateZone()
  },
  beforeDestroy() {
    this.options.zones.subscription.unsubscribe()
  },
  methods: {
    async getFestival() {
      if(this.id) {
        const response = await API.graphql(graphqlOperation(getFestival, { id: this.id }));
        this.festival = response.data.getFestival
        if(this.festival) {
          /** Check Current Year **/
          if(!this.isCreatedAtInCurrentYear(this.festival.createdAt)) {
            this.error = `You are currently viewing ${this.$store.state.settings.app.current.title} data. This application was created in ${new Date(this.festival.createdAt).getFullYear()}. Switch to that year to view this application.`
          }
          this.setCreatedAt(this.festival?.createdAt)
        }
        else {
          this.error = `Festival with id ${this.id} not found.`
        }
      }
      else {
        const response = await API.graphql(graphqlOperation(getFestivalBySlug, { slug: this.slug }));
        // eslint-disable-next-line prefer-destructuring
        this.festival = response.data.getFestivalBySlug.items[0]
      }
      this.$refs.layout.state.loading = false
    },
    async patchFestival(input) {
      try {
        await API.graphql(graphqlOperation(updateFestival, { input: input } ));
        this.notify({ title: 'Success', text: 'Festival was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        this.notify({ title: 'Error', text: 'Festival failed to update', icon: this.icon, variant: 'danger'});
      }
    },
    async updateFestival() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        await this.patchFestival({
          id: this.festival.id,
          name: this.festival.name,
          //slug: slugify(this.festival.name, { lower: true }),
          slug: slugify(`f${this.festival.name}z${this.festival.zone.name}`, { lower: true }),
          site: this.festival.site,
          address: this.festival.address,
          festivalZoneId: this.festival.zone ? this.festival.zone.id : null,
          createdAt: this.formattedCreatedAt
        })
      }
      else {
        this.notify({ title: 'Warning', text: 'Festival failed to update. Missing required fields.', icon: this.icon, variant: 'warning'});
      }
    },
    async deleteFestival(festival, swalCallback) {
      try {
        await this.cascadeDeleteFestival(festival.id, swalCallback)
        await this.notify({ title: 'Success', text: 'Festival was successfully deleted', icon: this.icon, variant: 'success' });
        await this.$router.push({ name: 'management-festivals' })
      }
      catch(error) {
        this.notify({ title: 'Error', text: 'Festival failed to delete', icon: this.icon, variant: 'danger'});
        throw error //for swal
      }
    },
    cascadeDeleteFestival,

    setCreatedAt() {
      if(this.festival?.createdAt) {
        // eslint-disable-next-line prefer-destructuring
        this.createdAtDate = this.festival.createdAt.split('T')[0]
        // eslint-disable-next-line prefer-destructuring
        this.createdAtTime = this.festival.createdAt.split('T')[1].split('.')[0]
      }
    },

    // eslint-disable-next-line no-shadow
    async updateAvatar(avatar) {
      this.festival.avatar = avatar;
      await this.patchFestival({ id: this.festival.id, avatar: this.festival.avatar })
    },
    async updateState() {
      this.festival.state.enabled = !this.festival.state.enabled;
      await this.patchFestival({ id: this.festival.id, state: this.festival.state })
    },

    async getZones() {
      const response = await API.graphql(graphqlOperation(listZones));
      this.options.zones.items = response.data.listZones.items.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }));
      this.options.zones.loading = false
    },
    async onUpdateZone() {
      this.options.zones.subscription = API.graphql(graphqlOperation(onUpdateZone)).subscribe((sourceData) => {
        this.syncNotification()
        const zone = sourceData.value.data.onUpdateZone
        if (zone && this.options.zones?.items?.map(item => item.id).includes(zone.id)) {
          this.options.zones.loading = true
          const index = this.options.zones.items.findIndex(item => item.id === zone.id)
          if(index > -1) {
            this.options.zones.items.splice(index, 1, zone)
          }
          this.options.zones.loading = false
        }
      });
    },

    async refresh() {
      this.$refs.layout.state.loading = true
      this.error = null
      await this.getFestival();
      await this.getZones();
      this.$refs.layout.state.loading = false
    },
    getValidationState({ dirty, validated, valid = null }) {
      if(this.$refs.layout.state.editing) {
        return dirty || validated ? valid : null;
      }
     return null;
    },
  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

html[dir='rtl'] {
  .flatpickr-calendar {
    .flatpickr-time {
      display: none!important;
      height: 0!important;
    }
  }
}


.input-group-border {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.input-group:focus-within {
  border-color: var(--primary);
}

.input-group-prepend-disabled {
  background-color: #efefef;
  border-color: #d8d6de;
  color: #6e6b7b;
  cursor: not-allowed;
}

.form-control-plaintext {
  border: 0!important;
  border-radius: 0!important;
  -webkit-box-shadow: unset!important;
  box-shadow: unset!important;
  padding: 0!important;
  margin: 0!important;
  transition: unset!important;
  height: unset!important;
  /*font-size: inherit!important;
  font-weight: inherit!important;*/
}

.form-control-plaintext[readonly] {
  background-color: unset!important;
  opacity: 1;
}

.form-control-plaintext:focus-visible {
  outline: 0;
}
.form-control-plaintext:focus {
  color: inherit;
  background-color: inherit;
  border: 0;
  outline: 0;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}
</style>
