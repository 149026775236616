/* eslint-disable no-await-in-loop */
import {API, graphqlOperation} from 'aws-amplify';
import {batchProcessWithRetry, SwalData} from '@/graphql/cascade/batch';

export const cascadeConfirmDeleteOptions = {
    confirm: {
        text: `
          <p class='mb-0'>Deleting this Festival may have unwanted side effects.</p>
          <p class='font-small-3 text-danger'>
            Applications currently associated with this Festival will have their association removed.<br/>
            This may effect applications, scoring, selections, acceptance, as well as reports.
          </p>
          <strong>Be very careful when performing this action.</strong>
        `,
        shouldParse: true
    },
    confirmed: { text: 'Festival was successfully deleted.' }
}

export async function cascadeDeleteFestival(festivalId, swalCallback) {
    try {
        /** Update Application records associated to this Festival **/
        const applications = await listApplications(festivalId, swalCallback)
        await batchProcessWithRetry(applications, async (application) => {
            await API.graphql(graphqlOperation(updateApplicationMutation, { input: { id: application.id, applicationFestivalId: null }}));
        }, { title: 'Updating Applications', callback: swalCallback })

        /** Delete Form **/
        await deleteFestival(festivalId, swalCallback)
    }
    catch(e) {
        console.error(e)
    }
}

/** Helper Functions **/
async function deleteFestival(festivalId, swalCallback) {
    const title = 'Deleting Festival'
    swalCallback(new SwalData(title, 0, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
    await API.graphql(graphqlOperation(deleteFestivalMutation, { input: { id: festivalId }}));
    swalCallback(new SwalData(title, 1, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
}
async function listApplications(festivalId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, filter: { applicationFestivalId: { eq: festivalId } } }
            const response = await API.graphql(graphqlOperation(listApplicationsQuery, input));
            items.push(...response.data.listApplications.items)
            nextToken = response.data.listApplications.nextToken;
            swalCallback(new SwalData('Loading Applications', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}

/** Queries & Mutations **/
const listApplicationsQuery = /* GraphQL */ `
    query ListApplications(
        $id: ID
        $filter: ModelApplicationFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listApplications(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                applicationFestivalId
            }
            nextToken
        }
    }
`;

const updateApplicationMutation = /* GraphQL */ `
    mutation UpdateApplication(
        $input: UpdateApplicationInput!
        $condition: ModelApplicationConditionInput
    ) {
        updateApplication(input: $input, condition: $condition) {
            id
            applicationFestivalId
        }
    }
`;

const deleteFestivalMutation = /* GraphQL */ `
    mutation DeleteFestival(
        $input: DeleteFestivalInput!
        $condition: ModelFestivalConditionInput
    ) {
        deleteFestival(input: $input, condition: $condition) {
            id
        }
    }
`;
